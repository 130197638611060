import React, { memo, useCallback } from "react";
import { Button, Modal, ModalProps, TextField, useForm } from "@app/components";
import * as yup from "yup";
import "./styles.scss";
import { useNotification } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { APIResponse, postVerificationEmail } from "@app/api";
import Cookies from "js-cookie";

interface Props extends Omit<ModalProps, "title"> {
  onClose: (val?: boolean, email?: string) => void;
}

const ModalEnterEmail = (props: Props) => {
  const { onClose, open, ...restProps } = props;
  const { showNotification } = useNotification();
  const { values, errors, onChange, validate, setPending, resetForm, pending } =
    useForm<{
      email: string;
    }>({
      values: {
        email: "",
      },
      schema: yup.object().shape({
        email: yup
          .string()
          .email("Введите корректный email")
          .required("Email обязателен для заполнения"),
      }),
      onSubmit: () => onClickSubmit(),
    });

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);
      Cookies.set("email", values.email);
      const res = await postVerificationEmail(values.email);

      if (res) {
        onClose(true, values.email);
      }

      setPending(false);
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [validate, setPending, values.email, onClose, showNotification]);

  const onModalClose = useCallback(() => {
    Cookies.remove("email");
    resetForm();
    onChange("", "email");
    onClose();
    // do not add 'resetForm' dependency
  }, [onClose]);

  return (
    <Modal
      open={open}
      title={"title"}
      position={"center"}
      size={"xsmall"}
      hideHeader
      onClose={onModalClose}
      {...restProps}
    >
      <div className="recovery-email-modal">
        <p className="recovery-email-modal__title">Введите ваш email</p>
        <div className="recovery-email-modal__form">
          <TextField
            label="Email"
            placeholder="Введите ваш email"
            value={values.email}
            name="email"
            error={!!errors.email}
            helperText={errors.email}
            onChange={onChange}
          />
          <Button
            text="Продолжить"
            disabled={pending}
            showLoader={pending}
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalEnterEmail);
