import { DocumentFile } from "@app/components";
import React, { FC, memo } from "react";
import { CompanyInfo } from "@app/models";
import { StyledGrid } from "./styled";
import { IconDeletedFile, IconDocumentBlue40 } from "@app/icons";

type Props = {
  company: CompanyInfo | null;
};

const FilesView: FC<Props> = (props) => {
  const { company } = props;

  if (!company) {
    return null;
  }

  return (
    <StyledGrid>
      {company.files.map((item, index) => {
        const { file } = item;
        return (
          <DocumentFile
            key={index.toString()}
            id={file.id}
            name={file.name || `${item?.id || index.toString()}`}
            previewEnabled={true}
            icon={
              item.isDeleted ? (
                <IconDeletedFile width={40} height={40} />
              ) : (
                <IconDocumentBlue40 />
              )
            }
          />
        );
      })}
    </StyledGrid>
  );
};

export default memo(FilesView);
