import React, { memo, useCallback, useEffect } from "react";
import { Button, Modal, ModalProps, TextField, useForm } from "@app/components";
import * as yup from "yup";
import { getAxiosErrorMessage, setMask } from "@app/helpers";
import {
  APIResponse,
  getVerificationEmail,
  postVerificationEmail,
} from "@app/api";
import "./styles.scss";
import Cookies from "js-cookie";
import { useCountdown } from "../../hooks/useCountdown";
import { useNotification } from "@app/providers";
import { AxiosError } from "axios";

interface Props extends Omit<ModalProps, "title"> {
  onClose: (val?: boolean) => void;
  email: string;
}

const ModalEmailVerification = (props: Props) => {
  const { onClose, open, email, ...restProps } = props;
  const { showNotification } = useNotification();
  const { timeStr, isExpired, setCountDownDate } = useCountdown(
    Cookies.get("send_time") || ""
  );

  const { values, errors, onChange, validate, setPending, pending } = useForm<{
    email: string;
    code: string;
  }>({
    values: {
      email: "",
      code: "",
    },
    schema: yup.object().shape({
      code: yup.string().length(4).required(),
      email: yup.string().required(),
    }),
  });

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const res = await getVerificationEmail(email, values.code);

      if (res) {
        //TODO убрать этот кусок или нет
        //
        // Cookies.set("accessToken", res.message);
        onClose(true);
      }

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [validate, setPending, values, onClose, showNotification]);

  useEffect(() => {
    if (open) {
      const storedEmail = Cookies.get("email");
      if (storedEmail) {
        onChange(storedEmail, "email");
      }
    }
    setCountDownDate(new Date(+(Cookies.get("send_time") || "")).getTime());
  }, [onChange, open, setCountDownDate]);

  const resendCode = async () => {
    try {
      setCountDownDate(new Date().getTime() + 1000 * 31);
      const isCodeSent = await postVerificationEmail(values.email);

      if (isCodeSent) {
        showNotification({
          message: "Код подтверждения отправлен повторно на вашу почту.",
          variant: "success",
        });
      } else {
        showNotification({
          message: "Ошибка при повторной отправке кода подтверждения.",
          variant: "error",
        });
      }
    } catch (e) {
      showNotification({
        message: "Произошла ошибка при повторной отправке кода подтверждения.",
        variant: "error",
      });
    }
  };

  const changeEmail = useCallback(() => {
    Cookies.remove("email");
    Cookies.remove("send_time");
    onChange("", "email");
    onClose();
  }, [onChange, onClose]);

  return (
    <Modal
      open={open}
      title={"title"}
      position={"center"}
      size={"xsmall"}
      hideHeader
      onClose={onClose}
      {...restProps}
    >
      <div className="otp-modal">
        <p className="otp-modal__title">Введите код из письма</p>
        <p className="otp-modal__subtitle">
          На ваш email {values.email} отправлено сообщение с кодом
        </p>
        <div className="otp-modal__form">
          <TextField
            placeholder="Код подтверждения"
            value={values.code}
            name="code"
            onChange={(value, name) => {
              const formatted = setMask(value.replace(/\D/g, ""), "####");
              onChange(formatted, name);
            }}
            error={!!errors.code}
            helperText={errors.code}
          />
          {isExpired ? (
            <a className="otp-modal__red-link" onClick={resendCode}>
              Получить новый код
            </a>
          ) : (
            <p className="otp-modal__caption">
              Получить новый код можно через&nbsp;
              <span className="otp-modal__caption--time">{timeStr}</span>
            </p>
          )}

          <Button
            text="Подтвердить"
            disabled={pending || values.code.length < 4}
            showLoader={pending}
            onClick={onClickSubmit}
            className="otp-modal__btn"
          />
        </div>
        <a onClick={changeEmail} className="otp-modal__link">
          Изменить почту
        </a>
      </div>
    </Modal>
  );
};

export default memo(ModalEmailVerification);
