import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, DateTimePicker } from "@app/components";
import FileComponent from "../../../components/File/File";
import DataProgressBar from "../../../components/DateProgressBar";
import { VehicleFile } from "@app/models";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const StyledDatePicker = styled.div`
  display: flex;
  width: 256px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 16px;
`;

const StyledDropZone = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 12px;
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;
  margin-bottom: 16px;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
`;

const StyledDropZoneTitle = styled.div`
  color: #636463;

  .link {
    color: #4f8bff;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1c2246;
  margin-bottom: 12px;
`;

const StyledDropZoneCaption = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
`;

const StyledCheckBoxContainer = styled.div`
  margin-bottom: 18px;
`;

export interface FileType {
  name: string;
  isExpirationDateRequired: boolean;
  id: string;
  isDeleted: boolean;
}

interface IsOwnedByOrganization {
  [key: string]: boolean;
}

interface Props {
  onFileDelete: (companyFileTypeId: string, fileIndex: number) => void;
  files: { [key: string]: any[] };
  setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: any[] }>>;
  filesToEdit: VehicleFile[];
  onDataChange: (value: string | null, key: string) => void;
  companyFileTypes: FileType[];
  filterParams: any;
  updateData?: any | null;
  isOwnedByOrganization: IsOwnedByOrganization;
  setIsOwnedByOrganization: React.Dispatch<
    React.SetStateAction<IsOwnedByOrganization>
  >;
}

function Step2(props: Props) {
  const {
    onFileDelete,
    files,
    setFiles,
    companyFileTypes,
    filesToEdit,
    onDataChange,
    filterParams,
    isOwnedByOrganization,
    setIsOwnedByOrganization,
    updateData,
  } = props;

  const [selectedOwnerCompanyId, setSelectedOwnerCompanyId] = useState<
    string | null
  >(null);

  const onChangeInput = useCallback(
    (companyFileTypeId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => ({
          ...prevFiles,
          [companyFileTypeId]: [
            ...(prevFiles[companyFileTypeId] || []),
            ...newFiles,
          ],
        }));
      }
    },
    [setFiles]
  );

  const onChangeLeaseInput =
    (orgId: string, companyFileTypeId: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const newFiles = Array.from(e.target.files);
        const fileKey = `${companyFileTypeId}-${orgId}`;
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fileKey]: [...(prevFiles[fileKey] || []), ...newFiles],
        }));
      }
    };

  useEffect(() => {
    Object.keys(isOwnedByOrganization).forEach((orgId) => {
      if (isOwnedByOrganization[orgId]) {
        setFiles((prevFiles) => {
          const newFiles = { ...prevFiles };
          Object.keys(newFiles).forEach((fileKey) => {
            if (fileKey.includes(orgId)) {
              delete newFiles[fileKey];
            }
          });
          return newFiles;
        });
      }
    });
  }, [isOwnedByOrganization, setFiles]);

  const selectedOrganizations = filterParams.organization || [];

  const handleCheckboxChange =
    (orgId: string, fileTypeId: any) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;

      if (isChecked) {
        setSelectedOwnerCompanyId(orgId);
        setIsOwnedByOrganization((prevState) => ({
          ...prevState,
          [orgId]: true,
        }));

        // Сбрасываем состояние других чекбоксов
        Object.keys(isOwnedByOrganization).forEach((key) => {
          if (key !== orgId) {
            setIsOwnedByOrganization((prevState) => ({
              ...prevState,
              [key]: false,
            }));
          }
        });
      } else {
        setSelectedOwnerCompanyId(null);
        setIsOwnedByOrganization((prevState) => ({
          ...prevState,
          [orgId]: false,
        }));
      }
    };

  return (
    <StyledContent>
      <StyledHeader>Документы</StyledHeader>
      {companyFileTypes.map((companyFileType) => {
        const filesForType = files[companyFileType.id] || [];
        if (companyFileType.name !== "Договор аренды") {
          return (
            <StyledDropZone key={companyFileType.id}>
              <StyledTitle>{companyFileType.name}</StyledTitle>
              {!files[companyFileType.id] ||
              files[companyFileType.id].length === 0 ? (
                <>
                  <StyledDropZoneTitle>
                    <span
                      className="link"
                      onClick={() => {
                        const fileInput = document.getElementById(
                          `file-input-${companyFileType && companyFileType.id}`
                        );
                        if (fileInput) {
                          fileInput.click();
                        }
                      }}
                    >
                      Загрузите
                    </span>{" "}
                    документ или перетащите его сюда
                  </StyledDropZoneTitle>
                  <StyledDropZoneCaption>
                    Форматы: doc, docx, pdf, png, jpeg; Максимальный размер: 3
                    мб
                  </StyledDropZoneCaption>
                </>
              ) : null}
              <input
                type="file"
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                id={`file-input-${companyFileType.id}`}
                onChange={onChangeInput(companyFileType.id)}
              />
              {filesForType.map((file, fileIndex) => {
                const foundFile = filesToEdit.find(
                  (item) =>
                    item.typeId === companyFileType.id && !item.isDeleted
                );
                return (
                  <FileComponent
                    key={fileIndex}
                    file={file}
                    fileId={foundFile?.fileId}
                    onDelete={() => onFileDelete(companyFileType.id, fileIndex)}
                  />
                );
              })}
            </StyledDropZone>
          );
        } else {
          return selectedOrganizations.map((org: any) => {
            const fileKey = `${companyFileType.id}-${org.id}`;
            const filesForOrg = files[fileKey] || [];
            return (
              <React.Fragment key={org.id}>
                {!isOwnedByOrganization[org.id] && (
                  <StyledDropZone key={org.id}>
                    <StyledTitle>{`${companyFileType.name} для ${org.name}`}</StyledTitle>
                    {!filesForOrg || filesForOrg.length === 0 ? (
                      <>
                        <StyledDropZoneTitle>
                          <span
                            className="link"
                            onClick={() => {
                              const fileInput = document.getElementById(
                                `file-input-lease-${org.id}`
                              );
                              if (fileInput) {
                                fileInput.click();
                              }
                            }}
                          >
                            Загрузите
                          </span>{" "}
                          документ или перетащите его сюда
                        </StyledDropZoneTitle>
                        <StyledDropZoneCaption>
                          Форматы: doc, docx, pdf, png, jpeg; Максимальный
                          размер: 3 мб
                        </StyledDropZoneCaption>
                      </>
                    ) : null}
                    <input
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                      id={`file-input-lease-${org.id}`}
                      onChange={onChangeLeaseInput(org.id, companyFileType.id)}
                    />
                    {filesForOrg?.map((file, fileIndex) => (
                      <FileComponent
                        key={fileIndex}
                        file={file}
                        onDelete={() => onFileDelete(fileKey, fileIndex)}
                      />
                    ))}
                    {companyFileType.isExpirationDateRequired && (
                      <StyledDatePicker>
                        <DateTimePicker
                          label="Документ годен до"
                          name={`${companyFileType.id}-${org.id}`}
                          onChange={(dateValue) => {
                            const formattedDate = dateValue
                              ? dateValue.toISOString()
                              : null;
                            onDataChange(formattedDate, fileKey);
                          }}
                          value={
                            filterParams[fileKey]
                              ? new Date(filterParams[fileKey])
                              : null
                          }
                          hideTime={true}
                        />
                      </StyledDatePicker>
                    )}
                    {filterParams[fileKey] && updateData !== null && (
                      <DataProgressBar
                        today={new Date()}
                        expirationDate={new Date(filterParams[fileKey])}
                      />
                    )}
                  </StyledDropZone>
                )}
                <StyledCheckBoxContainer>
                  <Checkbox
                    type="checkbox"
                    label={`Техника оформлена на владельца "${org.name}"`}
                    checked={selectedOwnerCompanyId === org.id}
                    onChange={handleCheckboxChange(org.id, companyFileType.id)}
                  />
                </StyledCheckBoxContainer>
              </React.Fragment>
            );
          });
        }
      })}
    </StyledContent>
  );
}

export default memo(Step2);
