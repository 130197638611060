import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  PhoneField,
  TextField,
  Toggle,
  useForm,
} from "@app/components";
import { Link, useNavigate } from "react-router-dom";
import { useNotification, useUser } from "@app/providers";
import {
  APIResponse,
  checkPhone,
  postVerificationEmail,
  SignUpData,
  UserRegistrationType,
} from "@app/api";
import * as yup from "yup";
import "./styles.scss";
import { AxiosError } from "axios";
import { getAxiosErrorMessage } from "@app/helpers";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  ModalEmailVerification,
  ModalRegistrationInstruction,
} from "@app/modals";
import { IconInfo20, IconInfoBlue } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import Cookies from "js-cookie";

interface Props {}

interface SignUpValues extends Omit<SignUpData, "userRegistration"> {
  asPartner: boolean;
}

const StyledButton = styled.div`
  width: 255px;
  justify-self: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledInfoText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${theme("color.blue")};
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e3ecfd;
  border-radius: 4px;
  padding: 16px;
`;

const StyledText = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${theme("color.blue")};
`;

const schema = yup.object().shape({
  firstName: yup.string().required("Поле обязательно к заполнению"),
  lastName: yup.string().required("Поле обязательно к заполнению"),
  iin: yup.string().required("Поле обязательно к заполнению"),
  phone: yup
    .string()
    .required("Поле обязательно к заполнению")
    .test(
      "phone",
      "Укажите корректный номер телефона",
      (val) => !!val && isValidPhoneNumber(val)
    ),
  email: yup
    .string()
    .nullable()
    .email("Введите корректный адрес электронной почты")
    .required("Поле обязательно к заполнению"),
  password: yup.string().min(8).required("Поле обязательно к заполнению"),
  confirmPassword: yup
    .string()
    .required("Поле обязательно к заполнению")
    .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
});

function FormSignUp(props: Props) {
  const navigate = useNavigate();
  const { sendCode, signUp, signIn } = useUser();
  const { showNotification } = useNotification();
  const [agree, setAgree] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [instructionModalVisible, setInstructionModalVisible] =
    useState<boolean>(false);
  const [iinLabel, setIinLabel] = useState("ИИН");
  const { values, errors, onChange, validate, setPending, pending } =
    useForm<SignUpValues>({
      values: {
        firstName: "",
        lastName: "",
        middleName: "",
        iin: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        asPartner: true,
      },
      schema,
    });

  const closeInstructionModal = useCallback(() => {
    setInstructionModalVisible(false);
  }, []);

  const onClickInstruction = useCallback(() => {
    setInstructionModalVisible(true);
  }, []);

  const onChangeAsPartner = useCallback(
    (val: boolean) => {
      onChange(val, "asPartner");
    },
    [onChange]
  );

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();
      if (!isValid) return;

      setPending(true);
      Cookies.set("email", values.email);
      const isCodeSent = await postVerificationEmail(values.email);
      setPending(false);

      if (isCodeSent) {
        setShowDialog(true);
      }
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [validate, setPending, showNotification, values]);

  const onAgreementClick = useCallback(() => {
    setAgree((prevState) => !prevState);
  }, []);

  const onPrivacyClick = useCallback(() => {
    setPrivacy((prevState) => !prevState);
  }, []);

  const onDialogClose = useCallback(
    async (val?: boolean) => {
      if (val) {
        try {
          const isCodeSent = await postVerificationEmail(values.email);

          if (!isCodeSent) {
            showNotification({
              message: "Ошибка при отправке кода подтверждения на почту",
              variant: "error",
            });
            return;
          }
          const isRegistrationSuccess = await signUp({
            ...values,
            phone: values.phone.substring(1),
            userRegistration: values.asPartner
              ? UserRegistrationType.IsPartner
              : UserRegistrationType.IsCustomer,
          });

          setShowDialog(false);

          if (isRegistrationSuccess) {
            showNotification({
              message: "Вы успешно зарегистрированы",
              variant: "success",
            });

            await signIn({
              username: values.phone.substring(1),
              password: values.password,
              asPartner: values.asPartner,
            });

            setPending(false);
            navigate("/main");
          }
        } catch (e) {
          setPending(false);
          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });
        }
      } else {
        setShowDialog(false);
      }
    },
    [navigate, setPending, showNotification, signIn, signUp, values]
  );

  const handlePhoneChange = useCallback(
    (value: string, name: any) => {
      onChange(value, name);

      // Проверка на код страны Узбекистана
      if (value.startsWith("+998")) {
        setIinLabel("ИНН");
      } else {
        setIinLabel("ИИН");
      }
    },
    [onChange]
  );

  const checkPhoneNumber = useCallback(() => {
    if (isValidPhoneNumber(values.phone)) {
      setPending(true);
      checkPhone(values.phone.substring(1)).then((res) => {
        setPending(false);
        setUserExist(res);
        if (res) {
          showNotification({
            message: "Данный номер уже зарегистрирован",
            variant: "error",
          });
        }
      });
    }
  }, [setPending, showNotification, values.phone]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onClickSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClickSubmit]);

  return (
    <div className="f-sign-up">
      <Grid gap={16}>
        <Grid gap={8}>
          <Toggle
            negativeLabel="Партнер"
            positiveLabel="Застройщик"
            value={!values.asPartner}
            onClick={(val) => onChangeAsPartner(!val)}
          />
          <StyledButton>
            <Button
              text="Как зарегистрироваться?"
              variant="newDesign"
              startIcon={IconInfo20}
              onClick={onClickInstruction}
            />
          </StyledButton>
          {values.asPartner && (
            <StyledInfoContainer>
              <StyledHeader>
                <IconInfoBlue />
                <StyledText>Уважаемый партнер!</StyledText>
              </StyledHeader>
              <StyledInfoText>
                При первичной регистрации организации,{" "}
                <strong>обязательно</strong> регистрируйте подписанта вашей
                организации. Это <strong>директор</strong> или подписант{" "}
                <strong>по</strong> <strong>доверенности</strong>.
              </StyledInfoText>
            </StyledInfoContainer>
          )}
          <PhoneField
            label="Номер телефона"
            placeholder="Введите номер телефона"
            value={values.phone}
            name="phone"
            error={!!errors.phone || isUserExist}
            helperText={
              errors.phone ||
              (isUserExist ? "Данный номер уже зарегистрирован" : undefined)
            }
            onChange={handlePhoneChange}
            onBlur={checkPhoneNumber}
          />
          <TextField
            label="Фамилия"
            placeholder="Введите фамилию"
            value={values.lastName}
            name="lastName"
            onChange={onChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            label="Имя"
            placeholder="Введите имя"
            value={values.firstName}
            name="firstName"
            onChange={onChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            label="Отчество"
            placeholder="Введите отчество"
            value={values.middleName}
            name="middleName"
            onChange={onChange}
          />
          <TextField
            label={iinLabel}
            placeholder={`Введите ${iinLabel.toLowerCase()}`}
            value={values.iin}
            name="iin"
            onChange={onChange}
            error={!!errors.iin}
            helperText={errors.iin}
          />
          <TextField
            label="Почта (для рассылок)"
            placeholder="Введите email"
            value={values.email}
            name="email"
            onChange={onChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Пароль"
            placeholder="Придумайте пароль"
            value={values.password}
            name="password"
            onChange={onChange}
            secureTextEntry={true}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            label="Подтвердить пароль"
            placeholder="Повторите пароль"
            value={values.confirmPassword}
            name="confirmPassword"
            onChange={onChange}
            secureTextEntry={true}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
          <Checkbox
            label={
              <span>
                Я ознакомлен и согласен с условиями&nbsp;
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Политики конфиденциальности”
                </Link>
              </span>
            }
            checked={privacy}
            fontSize={"11px"}
            onChange={onPrivacyClick}
          />
          <Checkbox
            label={
              <span>
                Я ознакомлен и согласен с условиями&nbsp;
                <Link
                  to="/public-offer-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Договора публичной оферты”
                </Link>
              </span>
            }
            checked={agree}
            fontSize={"11px"}
            onChange={onAgreementClick}
          />
        </Grid>
        <Button
          text="Зарегистрироваться"
          disabled={pending || !agree || !privacy || isUserExist}
          showLoader={pending}
          onClick={onClickSubmit}
        />
      </Grid>
      <ModalEmailVerification
        open={showDialog}
        persistent
        email={values.email}
        onClose={onDialogClose}
      />
      <ModalRegistrationInstruction
        open={instructionModalVisible}
        onClose={closeInstructionModal}
      />
    </div>
  );
}

export default memo(FormSignUp);
