import React, { memo, useCallback, useState } from "react";
import { FileWrapper } from "./components";
import { IconDocumentFile40, IconEye24, IconTrash24 } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import { formatFileSize, getAxiosErrorMessage } from "@app/helpers";
import { Button } from "../index";
import { PreviewModal } from "@app/common";
import { APIResponse, getFileById } from "@app/api";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";

interface Props {
  file: File;
  fileId?: string;
  fileIndex?: number;
  onDelete?: (fileIndex: number) => void;
  onClick?: (fileIndex: number) => void;
  loading?: boolean;
  actionTitle?: string;
  onActionClick?: () => void;
}

const StyledFileIcon = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  color: ${theme("color.blue")};
`;

const StyledFileInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledFileName = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  word-break: break-word;
`;

const StyledFileSize = styled.p`
  font-weight: 400;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-family: ${theme("fontFamily")};
  color: ${theme("color.gray")};
`;

const StyledFileControl = styled.button`
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  border-radius: 4px;
  color: ${theme("color.gray")};

  &:hover {
    background: ${theme("color.grayLight")};
    color: ${theme("color.dark")};
  }
`;

const PreviewButton = styled.button`
  height: 40px;
  width: 40px;
  padding: 8px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${theme("color.grayLight")};
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.08);
  }
`;

function FileComponent(props: Props) {
  const {
    file,
    onDelete,
    fileIndex,
    fileId,
    onClick,
    onActionClick,
    actionTitle = "",
    loading,
  } = props;
  const { showNotification } = useNotification();
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [pending, setPending] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!onDelete) {
        return;
      }

      onDelete(fileIndex!);
    },
    [fileIndex, onDelete]
  );

  const onClickFile = useCallback(() => {
    if (!onClick) {
      return;
    }

    onClick(fileIndex!);
  }, [onClick, fileIndex]);

  const getBlob = useCallback(async () => {
    try {
      setPending(true);
      const res = await getFileById(fileId!);
      setPending(false);
      return res;
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
      return null;
    }
  }, [fileId, showNotification]);

  const onPreviewClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!fileBlob) {
        const blob = await getBlob();
        setFileBlob(blob);
      }
      setShowModal(true);
    },
    [fileBlob, getBlob]
  );

  const onPreviewClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <FileWrapper onClick={onClickFile}>
        <StyledFileIcon>
          <IconDocumentFile40 />
        </StyledFileIcon>
        <StyledFileInfo>
          <StyledFileName>{file.name}</StyledFileName>
          {!!file.size && (
            <StyledFileSize>{formatFileSize(file.size)}</StyledFileSize>
          )}
        </StyledFileInfo>
        <PreviewButton onClick={onPreviewClick}>
          <IconEye24 />
        </PreviewButton>
        {!!onActionClick && (
          <Button
            text={actionTitle}
            size="small"
            showLoader={loading}
            onClick={onActionClick}
          />
        )}
        {!!onDelete && (
          <StyledFileControl onClick={onClickDelete}>
            <IconTrash24 />
          </StyledFileControl>
        )}
      </FileWrapper>

      <PreviewModal open={showModal} file={fileBlob} onClose={onPreviewClose} />
    </>
  );
}

export default memo(FileComponent);
